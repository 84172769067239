import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squats 4×6\\@75% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`Buy In: 50/40 Calorie Assault Bike`}</p>
    <p>{`3rds of`}</p>
    <p>{`15-Hang Power Cleans (115/75)(Rx+ 135/95)`}</p>
    <p>{`25-Wall Balls (20/14)`}</p>
    <p>{`Cash Out: 50/40 Calorie Assault Bike`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      